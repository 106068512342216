import { default as React } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { UiPhotoSwipe } from "@components";
import { HeroContainer, PortfolioContainer } from "@containers";
import { HeaderLayout, FooterLayout, Layout } from "@layouts";
import { getMarkdownNodeBySlug, getPageItemByScope, getSetting, hasPageItemByScope } from "@definitions/utils";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexArtistAndAuthorAuthorQuery {
      allMarkdown: allMarkdownRemark(
        filter: {frontmatter: {scope: {eq: "index-artist-and-author_author"}}}
      ) {
        edges {
          node {
            id
            frontmatter {
              author
              categories
              date(formatString: "LLL")
              scope
              slug
              title
            }
            html
          }
        }
      }

      footer: layout(layoutMetadata: {scope: {eq: FOOTER}}) {
        ...Footer
      }

      header: layout(layoutMetadata: {scope: {eq: HEADER}}) {
        ...Header
      }

      page: page(
        pageMetadata: {scope: {eq: "index-artist-and-author_author"}}
      ) {
        ...Page
      }

      router: layout(layoutMetadata: {scope: {eq: ROUTER}}) {
        ...Router
      }

      site: site {
        ...Site
      }
    }
`);


  return (
    <Layout
      title={getSetting(data.page?.pageSettings, "title")}
      className="white-version"
    >
      {(data.header && data.router) && (
        <HeaderLayout
          data={{
            header: data.header,
            router: data.router,
            socials: data.site?.siteMetadata?.socials
          }}
          className="rn-d-none"
        />
      )}
        <main className="main-page-wrapper">
          {hasPageItemByScope(data.page?.items, "hero") && (
            <HeroContainer
              id="hero"
              data={getPageItemByScope(data.page?.items, "hero")}
            />
          )}

          <UiPhotoSwipe
            isContained={false}
            withCaption
            withDownloadButton
            options={{showHideAnimationType: "fade"}}
          >
            {hasPageItemByScope(data.page?.items, "birkat-ha-bayit") && (
              <PortfolioContainer
                id="birkat-ha-bayit"
                data={{
                  item: getPageItemByScope(data.page?.items, "birkat-ha-bayit"),
                  node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "birkat-ha-bayit")
                }}
                className="birkat-ha-bayit"
                returnPath="/artist-and-author#galleries"
                isContained={true}
                isLightbox={true}
              />
            )}

            {hasPageItemByScope(data.page?.items, "eshet-chayil") && (
              <PortfolioContainer
                id="eshet-chayil"
                data={{
                  item: getPageItemByScope(data.page?.items, "eshet-chayil"),
                  node: getMarkdownNodeBySlug(data.allMarkdown?.edges, "eshet-chayil")
                }}
                className="eshet-chayil"
                returnPath="/artist-and-author#galleries"
                isContained={true}
                isLightbox={true}
              />
            )}
          </UiPhotoSwipe>
        </main>
      {(data.footer && data.footer) && (
        <FooterLayout
          data={{
            header: data.header,
            footer: data.footer
          }}
          className="section-separator"
        />
      )}
    </Layout>
  );
}


export default IndexPage;
